// src/components/MemberRenewContainer/MemberRenewContainer.jsx

import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { 
  useCreateMemberMutation,
  useFetchMembersQuery, 
  useGetAdministrativeDivisionsQuery,
  useUpdateMemberMutation,
} from 'store/api/baseApiSlice';
import NewLayoutWithHeader from 'components/layouts/new-layout-with-header';
import { camelCaseObject } from 'lib/utils';

// Import child components
import SidePanel from './SidePanel';
import DependentsSection from './DependentsSection';
import AlertMessage from './AlertMessage';
import HouseholdHead from './HouseholdHead';
import MemberFormModal from './MemberFormModal';
import ErrorAlert, { SuccessAlert } from 'containers/member-import/ErrorAlert';
import { lowerCase } from 'lodash';

/**
 * Utility Function: Extract UUID from URL
 */
const useUUIDFromURL = () => {
  const [uuid, setUUID] = useState(null);

  useEffect(() => {
    const parts = window.location.href.split('/');
    setUUID(parts[parts.length - 2] || null);
  }, []);

  return uuid;
};

export const isEligibleToRegister = member => {
  const relationship = lowerCase(member.relationshipToHead);
  const isMinorChild = member.age < 18 && (relationship === 'son' || relationship === 'daughter');
  const isSpouseOrSelf = relationship === 'husband' || relationship === 'wife' || relationship === 'self';

  if (isSpouseOrSelf || isMinorChild) {
    return true;
  }
  return false;
};

/**
 * Utility Functions: Create FormData for Member Operations
 */
const createMemberFormData = (memberData, householdId) => {
  const formData = new FormData();
  formData.append('full_name', memberData.fullName);
  formData.append('birthdate', memberData.birthdate);
  formData.append('gender', memberData.gender);
  formData.append('local_membership_number', memberData.localMembershipNumber);
  formData.append('membership_type', memberData.membershipType);
  formData.append('profession', memberData.profession);
  formData.append('relationship_to_head', memberData.relationshipToHead);
  formData.append('household_id', householdId);
  formData.append('enrolled_at', new Date().toISOString());
  formData.append('birthdate_accuracy', 'Y');

  if (memberData.photo) {
    formData.append('photo', memberData.photo);
  }

  return formData;
};

const updateMemberFormData = (memberData, householdId) => {
  const formData = new FormData();
  formData.append('id', memberData.id);
  formData.append('full_name', memberData.fullName);
  formData.append('birthdate', memberData.birthdate);
  formData.append('gender', memberData.gender);
  formData.append('local_membership_number', memberData.localMembershipNumber);
  formData.append('membership_type', memberData.membershipType);
  formData.append('profession', memberData.profession);
  formData.append('relationship_to_head', memberData.relationshipToHead);
  formData.append('household_id', householdId);
  formData.append('enrolled_at', new Date().toISOString());
  formData.append('birthdate_accuracy', 'Y');

  if (memberData.photo) {
    formData.append('photo', memberData.photo);
  }

  return formData;
};

/**
 * Component: Centered Modal for Alerts using React Portal
 */
export const CenteredModal = ({ children, onClose }) => {
  // Ensure modal-root exists
  const modalRoot = document.getElementById('modal-root');
  if (!modalRoot) return null;

  return ReactDOM.createPortal(
    <div className="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 tw-z-50">
      <div className="tw-relative tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-6 tw-max-w-md tw-w-full">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="
            tw-absolute 
            tw-top-2 
            tw-right-2 
            tw-flex 
            tw-items-center 
            tw-justify-center 
            tw-w-8 
            tw-h-8 
            tw-rounded-full 
            tw-bg-gray-200 
            tw-text-gray-700 
            tw-hover:bg-gray-300 
            tw-hover:text-gray-900 
            tw-active:bg-gray-400 
            tw-active:text-gray-800 
            tw-transition 
            tw-transform 
            tw-duration-200 
            tw-ease-in-out
            tw-focus:outline-none
          "
          aria-label="Close Modal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-4 tw-w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className='tw-mt-10'>
        {children}
        </div>
      </div>
    </div>,
    modalRoot
  );
};


/**
 * Component: Loading Indicator
 */
const LoadingIndicator = () => (
  <div className="tw-flex tw-items-center tw-justify-center tw-h-64">
    <div className="tw-loader tw-ease-linear tw-rounded-full tw-border-8 tw-border-t-8 tw-border-gray-200 tw-h-16 tw-w-16"></div>
    <style jsx>{`
      .tw-loader {
        border-top-color: #3498db;
        animation: spin 1s linear infinite;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

/**
 * Main Component: MemberRenewContainer
 */
const MemberRenewContainer = () => {
  const uuid = useUUIDFromURL();

  // State for selectors
  const [selectedGote, setSelectedGote] = useState(null);
  const [selectedEnrollmentPeriod, setSelectedEnrollmentPeriod] = useState(null);
  const [selectedPremium, setSelectedPremium] = useState(null);
  const [renewalProgress, setRenewalProgress] = useState({});


  // Alert state
  const [alert, setAlert] = useState({ message: null, type: null });

  // Modal state for adding new member
  const [isAdding, setIsAdding] = useState(false);
  const [newMember, setNewMember] = useState({
    fullName: '',
    birthdate: '',
    gender: '',
    localMembershipNumber: '',
    relationshipToHead: '',
    membershipType: '',
    profession: '',
    photo: null,
    id: null,
  });

  // RTK Query hooks
  const { data: administrativeDivisions = [], error: adminDivError, isLoading: isLoadingDivisions } = useGetAdministrativeDivisionsQuery();
  const [createMember] = useCreateMemberMutation();
  const [editMember] = useUpdateMemberMutation();

  const { data: membersData, isLoading, isError, error, refetch } = useFetchMembersQuery(uuid);
  const data = membersData ? membersData[0] : null;
  const householdEnrollmentRecord = data?.household_enrollment_record

  // State to manage dependents' renewal status
  const [dependents, setDependents] = useState([]);

  // Find the household head
  const householdHead = camelCaseObject(
    data?.members.find(
      (member) => member.relationship_to_head.toLowerCase() === 'self'
    ) || null
  );

  // Initialize dependents when data changes
  useEffect(() => {
    if (data && data.members) {
      const dependentsWithRenewal = data.members.map((member) => ({
        ...camelCaseObject(member),
        enabled: isEligibleToRegister(member)
      }));

      // Sorting logic for relationshipToHead: SELF first, then HUSBAND, WIFE, and others.
    const sortedDependents = dependentsWithRenewal.sort((a, b) => {
      const order = ['SELF', 'HUSBAND', 'WIFE'];
      const aIndex = order.indexOf(a.relationshipToHead);
      const bIndex = order.indexOf(b.relationshipToHead);
  
      if (aIndex === -1 && bIndex === -1) {
        // If both are not 'SELF', 'HUSBAND', or 'WIFE', keep their original order
        return 0;
      }
  
      // If only one is 'SELF', 'HUSBAND', or 'WIFE', prioritize that one
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
  
      // Compare based on their positions in the order array
      return aIndex - bIndex;
    });

      setDependents(sortedDependents);
    }
  }, [data]);

  /**
   * Handler to update a member's information
   */
  const handleUpdateMember = useCallback(
    async (updatedMember) => {
      try {
        const existingMember = dependents.find((dep) => dep.id === updatedMember.id);
        if (!existingMember) {
          setAlert({ message: 'Member not found.', type: 'error' });
          return;
        }

        // Use householdHead.householdId instead of existingMember.householdId
        const formData = updateMemberFormData(updatedMember, householdHead?.householdId || '');
        console.log(formData);
        await editMember(formData).unwrap();
        setAlert({ message: 'Member updated successfully.', type: 'success' });
        refetch(); // Refresh members data
      } catch (err) {
        console.error(err);
        setAlert({ message: 'Failed to update member.', type: 'error' });
      }
    },
    [dependents, editMember, householdHead, refetch]
  );

  /**
   * Handler to create a new member
   */
  const handleCreateMember = useCallback(
    async (memberData) => {
      try {
        const formData = createMemberFormData(memberData, householdHead?.householdId || '');
        await createMember(formData).unwrap();
        setIsAdding(false);
        setAlert({ message: 'New member added successfully.', type: 'success' });
        refetch();
      } catch (err) {
        console.error(err);
        setAlert({ message: 'Failed to add new member.', type: 'error' });
      }
    },
    [createMember, householdHead, refetch]
  );

  /**
   * Handler to add a new member
   */
  const handleAddMember = () => {
    setIsAdding(true);
  };

  /**
   * Handler for form field changes in Add Modal
   */
  const handleAddChange = (e) => {
    const { name, value, files } = e.target;
    setNewMember((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  /**
   * Handler for date changes in Add Modal
   */
  const handleAddDateChange = (gregorianDate, field) => {
    setNewMember((prev) => ({
      ...prev,
      [field]: gregorianDate,
    }));
  };

  // Combined Loading State
  const isLoadingCombined = isLoading || isLoadingDivisions;

  return (
    <NewLayoutWithHeader pageTitle="Member Renewal" steps={[]}>
      {isLoadingCombined ? (
        <LoadingIndicator />
      ) : isError || adminDivError ? (
        <div className="tw-text-center tw-text-red-500">
          Error: {error?.data?.message || adminDivError?.data?.message || 'Failed to load members or administrative divisions.'}
        </div>
      ) : (
        <div className="tw-p-4 tw-flex tw-bg-gray-100">
          <SidePanel 
            renewalProgress={renewalProgress}
            setRenewalProgress={setRenewalProgress}
            setDependents={setDependents}
            dependents={dependents} 
            administrativeDivisions={administrativeDivisions}
            selectedGote={selectedGote}
            setSelectedGote={setSelectedGote}
            selectedPremium={selectedPremium}
            setSelectedPremium={setSelectedPremium}
            selectedEnrollmentPeriod={selectedEnrollmentPeriod}
            setSelectedEnrollmentPeriod={setSelectedEnrollmentPeriod}
            householdEnrollmentRecord={householdEnrollmentRecord}
            refetch={refetch}
            householdHead={householdHead}
          />

          <div className="tw-w-full tw-ml-4 lg:tw-w-9/12 ">
            <HouseholdHead householdHead={householdHead} />
            <DependentsSection
              renewalProgress={renewalProgress} 
              dependents={dependents} 
              setDependents={setDependents}
              handleAddMember={handleAddMember} 
              updateMember={handleUpdateMember} 
            />
          </div>
        </div>
      )}

      {/* Member Form Modal for Adding New Member */}
      <MemberFormModal
        showModal={isAdding}
        toggleModal={() => setIsAdding(false)}
        newMember={newMember}
        handleChange={handleAddChange}
        saveMember={handleCreateMember}
        isEditing={false}
        hasHouseholdHead={Boolean(householdHead)}
        handleDateChange={handleAddDateChange}
        errors={{}}
      />

      {/* Alert Modal */}
      {alert.message && (
        <CenteredModal onClose={() => setAlert({ message: null, type: null })}>
          {alert.type === 'alert' &&<ErrorAlert errors={[alert.message]} />}
          {alert.type === 'success' &&<SuccessAlert message={alert.message} />}
        </CenteredModal>
      )}
    </NewLayoutWithHeader>
  );
};

export default MemberRenewContainer;
