import React, { useState } from 'react';
import { RELATIONSHIPS, PROFESSIONS } from './constants';
import moment from 'moment'; // Assuming you're using moment for date manipulation
import { DatePicker } from 'components/inputs';
import { formatDate } from 'lib/formatters/date';
import { upperCase } from 'lodash';

const MemberFormModal = ({ showModal, toggleModal, newMember, handleChange, saveMember, isEditing, hasHouseholdHead, handleDateChange, errors }) => {
  const [validationErrors, setValidationErrors] = useState({});

  if (!showModal) return null;

  // Validation function to check for required fields
  const validateForm = () => {
    const errors = {};

    // Full Name Validation - Ensure it has exactly three words
    const fullNameParts = newMember.fullName.trim().split(' ');
    if (fullNameParts.length !== 3) {
      errors.fullName = 'Full Name must be three words (e.g., Desta Hagos Gamachu)';
    }

    if (!newMember.fullName.trim()) errors.fullName = 'Full Name is required';
    if (!newMember.birthdate) errors.birthdate = 'Date of Birth is required';
    if (!newMember.gender) errors.gender = 'Gender is required';
    if (!newMember.localMembershipNumber.trim()) errors.localMembershipNumber = 'Local Membership Number is required';
    if (!newMember.relationshipToHead) errors.relationshipToHead = 'Relationship to Head is required';
    if (!newMember.profession) errors.profession = 'Profession is required';
    setValidationErrors(errors);
    console.log({errors});
    return Object.keys(errors).length === 0;
  };

  const handleSaveMember = () => {
    console.log(newMember);

    if (validateForm()) {
      saveMember(newMember);
    }
  };

  return (
    <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-50 tw-flex tw-justify-center tw-items-center">
      <div className="tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-lg">
        <h3 className="tw-text-xl tw-font-bold tw-mb-4">{isEditing ? 'Edit Member' : 'Add Member'}</h3>
        <form>
          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700">Full Name</label>
            <input
              type="text"
              name="fullName"
              value={newMember.fullName}
              onChange={handleChange}
              className={`tw-w-full tw-p-2 tw-border ${validationErrors.fullName ? 'tw-border-red-500' : 'tw-border-gray-300'} tw-rounded`}
              required
            />
            {validationErrors.fullName && <p className="tw-text-red-500 tw-text-sm">{validationErrors.fullName}</p>}
          </div>

          {/* DatePicker for birthdate */}
          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700">Date of Birth</label>
            <DatePicker
              label="Date of Birth"
              onChange={(gregorianDate, ethiopianDate) => handleDateChange(gregorianDate, 'birthdate')}
              defaultDate={formatDate(newMember.birthdate)}
              maxDate={formatDate(moment())}
              name="birthdate"
              overlayCalendar={true}
              error={validationErrors.birthdate}
            />
            {validationErrors.birthdate && <p className="tw-text-red-500 tw-text-sm">{validationErrors.birthdate}</p>}
          </div>

          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700">Gender</label>
            <select
              name="gender"
              value={newMember.gender}
              onChange={handleChange}
              className={`tw-w-full tw-p-2 tw-border ${validationErrors.gender ? 'tw-border-red-500' : 'tw-border-gray-300'} tw-rounded`}
              required
            >
              <option value="">Select Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
            {validationErrors.gender && <p className="tw-text-red-500 tw-text-sm">{validationErrors.gender}</p>}
          </div>

          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700">Local Membership Number</label>
            <input
              type="text"
              name="localMembershipNumber"
              value={newMember.localMembershipNumber}
              onChange={handleChange}
              className={`tw-w-full tw-p-2 tw-border ${validationErrors.localMembershipNumber ? 'tw-border-red-500' : 'tw-border-gray-300'} tw-rounded`}
              required
            />
            {validationErrors.localMembershipNumber && <p className="tw-text-red-500 tw-text-sm">{validationErrors.localMembershipNumber}</p>}
          </div>

          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700">Relationship to Head</label>
            <select
              name="relationshipToHead"
              value={newMember.relationshipToHead}
              onChange={handleChange}
              className={`tw-w-full tw-p-2 tw-border ${validationErrors.relationshipToHead ? 'tw-border-red-500' : 'tw-border-gray-300'} tw-rounded`}
              required
            >
              <option value="">Select Relationship</option>
              {Object.values(RELATIONSHIPS).map((relationship, index) => (
                <option key={index} value={upperCase(relationship)}>
                  {relationship}
                </option>
              ))}
            </select>
            {validationErrors.relationshipToHead && <p className="tw-text-red-500 tw-text-sm">{validationErrors.relationshipToHead}</p>}
          </div>

          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700">Profession</label>
            <select
              name="profession"
              value={newMember.profession}
              onChange={handleChange}
              className={`tw-w-full tw-p-2 tw-border ${validationErrors.profession ? 'tw-border-red-500' : 'tw-border-gray-300'} tw-rounded`}
              required
            >
              <option value="">Select Profession</option>
              {Object.values(PROFESSIONS).map((profession, index) => (
                <option key={index} value={upperCase(profession)}>
                  {profession}
                </option>
              ))}
            </select>
            {validationErrors.profession && <p className="tw-text-red-500 tw-text-sm">{validationErrors.profession}</p>}
          </div>

          <div className="tw-mb-4">
            <label className="tw-block tw-text-gray-700">Upload Photo</label>
            <input
              type="file"
              name="photo"
              onChange={handleChange}
              className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
            />
          </div>

          <div className="tw-flex tw-justify-end">
            <button
              type="button"
              onClick={handleSaveMember}
              className="tw-bg-green-500 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-mr-2"
            >
              {isEditing ? 'Update Member' : 'Add Member'}
            </button>
            <button
              type="button"
              onClick={toggleModal}
              className="tw-bg-red-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MemberFormModal;
