// src/store/api/baseApiSlice.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import session from 'lib/session';
import { snakeCaseObject } from 'lib/utils';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_COVERAGE_API_BASE,
  prepareHeaders: (headers, { getState }) => {
    const tokenWithExpiry = session.getTokenWithExpiry();
    if (tokenWithExpiry && tokenWithExpiry.token) {
      headers.set('Authorization', `Token ${tokenWithExpiry.token}`);
    }
    return headers;
  },
});
export const baseApiSlice = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: builder => ({
    getAdministrativeDivisions: builder.query({
      query: () => '/administrative_divisions',
    }),
    getEnrollmentPeriods: builder.query({
      query: (administrativeDivisionId) => `/enrollment_periods?administrative_division_id=${administrativeDivisionId}`,
    }),
    importMembers: builder.mutation({
      query: (formData) => ({
        url: '/members/import',
        method: 'POST',
        body: formData,
      }),
    }),
    createHousehold: builder.mutation({
      query: (household) => ({
        url: '/households',
        method: 'POST',
        body: household,
      }),
    }),

    createHouseholdEnrollmentRecord: builder.mutation({
      query: (enrollmentRecord) => ({
        url: '/household_enrollment_records',
        method: 'POST',
        body: enrollmentRecord,
      }),
    }),

    createMember: builder.mutation({
      query: (member) => ({
        url: '/members',
        method: 'POST',
        body: member,
      }),
      invalidatesTags: ['HouseholdMembers']
    }),
    updateMember: builder.mutation({
      query: (member) => ({
        url: `/members/${member.get('id')}`,
        method: 'PUT',
        body: member,
      }),
      invalidatesTags: ['HouseholdMembers']
    }),
    createMemberEnrollmentRecord: builder.mutation({
      query: (enrollmentRecord) => ({
        url: '/member_enrollment_records',
        method: 'POST',
        body: enrollmentRecord,
      }),
    }),
    getPremiaByEnrollmentPeriod: builder.query({
      query: (enrollmentPeriodId) => `/enrollment_periods/${enrollmentPeriodId}/premia`,
    }),
    fetchMembers: builder.query({
      query: (member) => `/households/search?member_id=${member}`,
      providesTags: ['HouseholdMembers']
    }),
    createMembershipPayment: builder.mutation({
      query: (membershipPayments) => ({
        url: '/membership_payments',
        method: 'POST',
        body: membershipPayments,
      }),
    }),
  }),
});

export const { useGetAdministrativeDivisionsQuery, useGetEnrollmentPeriodsQuery, useImportMembersMutation,
  useCreateHouseholdMutation,
  useCreateHouseholdEnrollmentRecordMutation,
  useCreateMemberMutation,
  useCreateMemberEnrollmentRecordMutation,
  useGetPremiaByEnrollmentPeriodQuery,
  useFetchMembersQuery,
  useUpdateMemberMutation,
  useCreateMembershipPaymentMutation
 } = baseApiSlice;
